import { createContext, useEffect, useState } from 'react';
import './App.css';
import { FBInitialSetup, db, doLogin, doLogout } from './firebase';
import Profile from './docs-conf/Profile';
import { Button, Container, Icon, Menu, Segment } from 'semantic-ui-react';
import Persons from './docs-conf/Persons';
import Photo from './docs-conf/Photo';
import Payment from './docs-conf/Payment';
import { onValue, ref, set } from 'firebase/database';
import Bonafide from './docs-conf/Bonafide';
import { HashRouter, Link, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import Home from './docs-conf/Home';
import A2HS from './A2HS';
const loginbg = require('./images/account.png')

export const MyContext = createContext(null)

function App() {

  const menudata = [
    { name: 'home', icon: 'home' },
    { name: 'persons', icon: 'users' },
    { name: 'payment', icon: 'rupee' },
  ]
  const [data, setData] = useState(null)
  const [user, setUser] = useState(null)
  const [category, setCategory] = useState(null);
  const [accomp, setAccomp] = useState(0)
  const [ppersons, setPPersons] = useState(0)
  const [pids, setPids] = useState([])
  const [amount, setAmount] = useState(0)
  const [options, setOptions] = useState(null)
  const [loading, setLoading] = useState(false)

  let oldTheme = false
  if (localStorage.getItem('theme')) {
    oldTheme = JSON.parse(localStorage.getItem('theme'))
  }
  const [theme, setTheme] = useState(oldTheme)
  useEffect(() => {
    document.body.style.backgroundColor = theme ? 'black' : 'white'
    localStorage.setItem('theme', JSON.stringify(theme))
  }, [theme])

  FBInitialSetup(setUser)

  useEffect(() => {
    if (user) {
      const personRef = ref(db, 'users/' + user.uid);
      onValue(personRef, (snapshot) => {
        const res = snapshot.val();
        if (res) {
          setData(res)
          setCategory(res?.details?.category)
          if (res.persons) {
            setAccomp(Object.keys(res.persons).length)
          } else {
            setAccomp(0)
          }
        } else {
          setData(null)
          setAccomp(0)
        }
      });
      onValue(ref(db, 'options'), (snapshot) => {
        const res = snapshot.val()
        if (res) {
          setOptions(res)
        }
      })
    }
  }, [user])

  useEffect(() => {
    if (options) {
      let newAmount = 0
      if (!data?.payment?.delegate) {
        newAmount = options.price.category[category]
        setAmount(newAmount);
      }
      let x = 0
      let temp_arr = []
      if (data?.persons) {
        Object.keys(data?.persons).forEach(key => {
          if (data?.payment?.hasOwnProperty('accom')) {
            if (!data.payment.accom[key]) {
              temp_arr.push(key)
              newAmount += options.price.person
              x++
            }
          } else {
            temp_arr.push(key)
            newAmount += options.price.person
            x++
          }
        });
        setPPersons(x)
        setPids(temp_arr)
        setAmount(newAmount)
      } else {
        setPPersons(0)
      }
    }
  }, [options, accomp, category])

  useEffect(() => {
    if (user) {
      const emailRef = ref(db, 'users/' + user.uid + '/details/email')
      set(emailRef, user.email)
    }
  }, [user])

  function MainMenu() {
    const location = useLocation();
    const path = location.pathname;
    return (<div style={{ backgroundColor: theme ? "black" : 'white' }}>

      <div style={{ color: theme ? 'white' : 'black', paddingBottom: 10 }}>
        <h3 style={{ textAlign: 'center', paddingTop: 10, marginBottom: 5 }}>
          Indian Psychiatric Society South Zonal Branch<br /> Mid Term CME for Practitioners - 2024
        </h3>
        <p style={{ textAlign: 'center' }}>Contact Technical Support: <b><a target='_blank' href="https://wa.me/919989276655?text=Hai"><ins>+919989276655</ins></a></b></p>
        <p style={{ color: 'red', fontSize: 18, fontWeight: 'bold', textAlign: 'center', margin: 10 }}>Online registrations closed.</p>
      </div>

      {user ?
        <div style={{ textTransform: 'capitalize' }}>
          <Menu icon='labeled' compact widths={5} size='mini' inverted={theme}>
            {menudata.map((item) =>
              <Menu.Item key={item.name} name={item.name}
                as={Link} to={item.name}
                active={path === '/' + item.name}
                color={path === '/' + item.name ? 'blue' : 'black'}
              >
                <Icon name={item.icon} />
                <span style={{ textTransform: 'capitalize' }}>{item.name}</span>
              </Menu.Item>
            )}
            <Menu.Item onClick={() => { setTheme(!theme) }} >
              <Icon name={theme ? 'moon' : 'sun outline'} />{theme ? 'Dark' : 'Light'}
            </Menu.Item>
            <Menu.Item color='red' name={`logout`} onClick={doLogout}>
              <Icon name={`sign-out`} color='red' /> Logout
            </Menu.Item>
          </Menu>
        </div>
        :
        <><br /><br /></>
      }
      <Container style={{ height: '100vh', padding: 10 }}>
        <div style={{ backgroundColor: 'rgba(ff, ff, ff, 1)' }}>

          {user ?
            <>
              <Segment loading={loading} inverted={theme}>
                <Outlet />
              </Segment>
            </>
            :
            <Container>
              <div style={{ textAlign: 'center' }}>
                <img src={loginbg} alt="" style={{ width: 300 }} />
                <h2>Join Now</h2>
                <Button color='green' icon labelPosition='left' onClick={doLogin}>
                  <Icon name='google' />Login with Google
                </Button>
                <br />
                <br />
                <A2HS user={user} data={data} />
              </div>
            </Container>
          }
        </div>
      </Container>
    </div>)
  }

  return (
    <MyContext.Provider value={{ user, setUser, theme, accomp, data, ppersons, options, category, setCategory, amount, loading, setLoading, pids }}>
      <HashRouter>
        <Routes>
          <Route path='' element={<MainMenu />}>
            <Route path='' element={<Home />} />
            <Route path='home' element={<Home />} />
            <Route path='editProfile' element={<Profile paid={true} />} />
            <Route path='persons' element={<Persons amount={amount} />} />
            <Route path='photo' element={<Photo />} />
            <Route path='bonafide' element={<Bonafide />} />
            <Route path='payment' element={<Payment amount={amount} setAmount={setAmount} />} />
          </Route>
        </Routes>

      </HashRouter>
    </MyContext.Provider>
  );
}

export default App;
