import React, { useContext, useState } from 'react'
import { Button, Form, Grid, Icon, Modal, Radio, Segment, Table } from 'semantic-ui-react'
import { db } from '../firebase';
import { push, ref, set } from 'firebase/database';
import { MyContext } from '../App';
import { Link } from 'react-router-dom';

export default function Persons({ amount }) {
  const { user, data, theme } = useContext(MyContext)
  const [name, setName] = useState('')
  const [relationship, setRelationship] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [foodPreference, setFoodPreference] = useState('');

  const [box, setBox] = useState(false)
  const accRef = ref(db, 'users/' + user.uid + '/persons/')

  function addPerson() {
    if (!name || !relationship || !age || !gender || !foodPreference) {
      return
    }
    const person = { name, relationship, age, gender, foodPreference }
    const newKey = push(accRef)
    set(newKey, person)
    closeBox()
  }
  function deleteItem(id) {
    set(ref(db, `/users/${user?.uid}/persons/${id}`), null)
  }
  function closeBox() {
    setName('')
    setRelationship('')
    setAge('')
    setGender('')
    setFoodPreference('')
    setBox(false)
  }

  return (
    <div>
      {data?.details?.category ?
        <>
          {amount > 0 ? <h3>Amount Payable: {amount}</h3> : ''}
          <Button onClick={() => setBox(true)} color='green'>Add Person</Button>
          <Modal
            open={box}
            onOpen={() => setBox(true)}
            onClose={() => setBox(false)}
            closeIcon={false}
            closeOnDimmerClick={false}
          >
            <Modal.Content>
              <Segment inverted={theme}>
                <h3>Add Accompany Person</h3>
                <Form inverted={theme}>
                  <Grid columns={2} stackable>

                    <Grid.Column width={12}>
                      <Form.Input label='Name'
                        value={name}
                        onChange={e => setName(e.target.value)} />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <Form.Input label='Relationship'
                        value={relationship}
                        onChange={e => setRelationship(e.target.value)} />
                    </Grid.Column>
                  </Grid>

                  <Grid columns={3} stackable>

                    <Grid.Column width={4}>
                      <Form.Input label='Age'
                        type='number' max='99'
                        value={age}
                        onChange={e => setAge(e.target.value)} />
                    </Grid.Column>

                    <Grid.Column width={6}>
                      <Form.Field>
                        <label>Gender</label>
                        <Form.Select
                          placeholder='Select Gender'
                          options={[
                            { key: 'male', text: 'Male', value: 'Male' },
                            { key: 'female', text: 'Female', value: 'Female' },
                            { key: 'other', text: 'Others', value: 'Other' },
                          ]}
                          value={gender}
                          onChange={(e, { value }) => setGender(value)}
                        />
                      </Form.Field>
                    </Grid.Column>

                    <Grid.Column width={5}>
                      <Form.Field>
                        <label>Food Preference</label>
                        <Form.Select
                          placeholder='Select Food Preference'
                          options={[
                            { key: 'Vegetarian', text: 'Vegetarian', value: 'Vegetarian' },
                            { key: 'Non-Vegetarian', text: 'Non-Vegetarian', value: 'Non-Vegetarian' },
                          ]}
                          value={foodPreference}
                          onChange={(e, { value }) => setFoodPreference(value)}
                        />
                      </Form.Field>
                    </Grid.Column>

                  </Grid>

                </Form>
                <br />
                <Button color='blue' onClick={addPerson} icon='plus' content='Add New Person' />
                <Button color='grey' onClick={closeBox} content='Cancel' />
              </Segment>
            </Modal.Content>
          </Modal>
          <hr />

          <Table inverted={theme} celled unstackable>
            <thead>
              <tr>
                <th>Accompany Person Details</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data?.persons ?
                Object.entries(data.persons).map((item) =>
                  <tr key={item[0]}>
                    <td>{item[1].name}
                      <br />Age: {item[1].age}
                      <br />({item[1].gender})
                      <br />Rel: {item[1].relationship}
                      <br />Food: {item[1].foodPreference}</td>
                    <td>
                      {data.payment?.accom ? (data.payment.accom[item[0]] ? <Icon name='check circle' size='large' color='green' /> :
                        <Icon name='trash' onClick={() => deleteItem(item[0])} size='large' color='red' />)
                        :
                        <Icon name='trash' onClick={() => deleteItem(item[0])} size='large' color='red' />
                      }
                    </td>
                  </tr>
                ) : ''}
            </tbody>
          </Table>
          <Button color='blue' as={Link} to='/payment'>Go to Payments page</Button>
        </>
        :
        <>
          <h3>Loading...</h3>
        </>
      }
    </div >
  )
}
