import React, { useContext, useEffect, useState } from 'react'
import './home.css'
import { Dropdown, Form, Input, Radio, TextArea, Button, Icon } from 'semantic-ui-react';
import { ref, set } from 'firebase/database';
import { db } from '../firebase';
import { MyContext } from '../App';
import { useNavigate } from 'react-router-dom';

export default function Profile({ paid }) {
  const { user, data, category, setCategory, amount } = useContext(MyContext)
  const navigate = useNavigate()
  const [mcinumber, setMcinumber] = useState('');
  const [membershipNo, setMembershipNo] = useState('');
  const [knlmembershipNo, setknlMembershipNo] = useState('');
  const [salutation, setSalutation] = useState('');
  const [fullName, setFullName] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [phone, setPhone] = useState('');
  const [foodPreference, setFoodPreference] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [pincode, setPincode] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');

  useEffect(() => {
    if (user) {
      if (data?.details) {
        setMembershipNo(data?.details?.membershipNo)
        setknlMembershipNo(data?.details?.knlmembershipNo)
        setMcinumber(data?.details?.mcinumber)
        setPhone(data?.details?.phone)
        setCategory(data?.details?.category)
        setSalutation(data?.details?.salutation)
        setFullName(data?.details?.fullName)
        setAge(data?.details?.age)
        setGender(data?.details?.gender)
        setFoodPreference(data?.details?.foodPreference)
        setAddress(data?.details?.address)
        setCity(data?.details?.city)
        setState(data?.details?.state)
        setPincode(data?.details?.pincode)
        setCountry(data?.details?.country)
      }
    }
  }, [user, data])

  useEffect(() => {
    if (user && category) {
      set(ref(db, '/users/' + user?.uid + '/details/category'), category)
    }
  }, [category])


  function saveData() {
    const userData = { category, salutation, fullName, age, gender, foodPreference, email: user.email, phone, address, city, pincode, state, country }
    const userRef = ref(db, '/users/' + user?.uid + '/details')
    if (
      !category || !salutation || !fullName
      || !age || !gender || !foodPreference
      || !phone || !mcinumber
      || !address || !city || !pincode || !state || !country
    ) {
      alert('Enter all details.')
      return
    }
    if (membershipNo) {
      userData['membershipNo'] = membershipNo
    }
    if (knlmembershipNo) {
      userData['knlmembershipNo'] = knlmembershipNo
    }
    if (mcinumber) {
      userData['mcinumber'] = mcinumber
    }
    set(userRef, userData)
      .then(() => {
        if (paid) {
          navigate('/home')
        } else if (category === '4') {
          navigate('/bonafide')
        } else {
          navigate('/photo')
        }
      })
      .catch((error) => console.error(error))
  }

  return (
    <div className='ui container'>
      {amount > 0 ? <h3>Amount Payable: {amount}</h3> : ''}
      <Form className="ui form">
        {!paid &&
          <Form.Field>
            <label>Category</label>
            <Dropdown
              disabled={data?.payment?.delegate}
              clearable
              fluid
              selection
              id="category"
              name="category"
              value={category}
              onChange={(e, d) => setCategory(d.value)}
              options={[
                { key: '1', text: 'IPS South Zonal Member', value: '1' },
                { key: '2', text: 'IPS South Zonal Non-Member', value: '2' },
                { key: '3', text: 'IPS South Zonal Senior Member (above 65 years)', value: '3' },
                { key: '4', text: 'PG Student', value: '4' },
                { key: '6', text: 'Kurnool IMA Member', value: '6' },
                { key: '5', text: 'Indian Corporate', value: '5' },
                // { key: '7', text: 'Test User', value: '7' },
              ]}
            />
          </Form.Field>
        }

        {category === '4' && !paid && <p>Bonafide certificate is required for students.</p>}
        {(category === '1' || category === '3') &&
          <>
            <Form.Group widths='equal'>
              <Form.Field>
                <label>IPS Southzone Membership Number / IPS Membership Number</label>
                <Input type="text"
                  name="membershipNo"
                  value={membershipNo}
                  onChange={(e) => setMembershipNo(e.target.value)}
                />
              </Form.Field>
            </Form.Group>
          </>
        }
        {category === '6' &&
          <>
            <Form.Group widths='equal'>
              <Form.Field>
                <label>Kurnool IMA Membership Number</label>
                <Input type="text"
                  name="knlmembershipNo"
                  value={knlmembershipNo}
                  onChange={(e) => setknlMembershipNo(e.target.value)}
                />
              </Form.Field>
            </Form.Group>
          </>
        }

        <Form.Group widths='equal'>
          <Form.Field>
            <label>MCI Number</label>
            <Input type="text"
              name="mcinumber"
              value={mcinumber}
              onChange={(e) => setMcinumber(e.target.value)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group widths='equal'>
          <Form.Field>
            <label>Salutation</label>
            <Dropdown
              fluid
              selection
              name="salutation"
              value={salutation}
              onChange={(e, d) => setSalutation(d.value)}
              options={[
                { key: 'Dr.', text: 'Dr.', value: 'Dr.' },
                { key: 'Prof.', text: 'Prof.', value: 'Prof.' },
                { key: 'Mr.', text: 'Mr.', value: 'Mr.' },
                { key: 'Mrs.', text: 'Mrs.', value: 'Mrs.' },
                { key: 'Ms.', text: 'Ms.', value: 'Ms.' },
                { key: 'Shri.', text: 'Shri.', value: 'Shri.' },
                { key: 'Kumari.', text: 'Kumari.', value: 'Kumari.' },
                { key: 'Smt.', text: 'Smt.', value: 'Smt.' },
              ]}
            />
          </Form.Field>
          <Form.Field>
            <label>Full Name</label>
            <Input type="text" name="fullname" placeholder="Full Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group widths='equal'>
          <Form.Field>
            <label>Phone <i>(10 digits)</i></label>
            <Input type="text" name="phone" placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value.trim())}
            />
          </Form.Field>
          <Form.Field>
            <label>Email <i>(readonly)</i></label>
            <Input type="email" name="email" placeholder="Email"
              readonly={true}
              value={user?.email}
            />
          </Form.Field>
          <Form.Field>
            <label>Age</label>
            <Input type="number" name="age" id="age" min="10" max="99"
              value={age}
              onChange={(e) => setAge(parseInt(e.target.value))}
            />
          </Form.Field>
        </Form.Group>

        <Form.Field>
          <label>Gender</label>
          <Form.Group inline>
            <Form.Field>
              <Radio label='Male' name='gender' value='Male'
                checked={gender === 'Male'}
                onClick={() => setGender('Male')}
              />
            </Form.Field>
            <Form.Field>
              <Radio label='Female' name='gender' value='Female'
                checked={gender === 'Female'}
                onClick={() => setGender('Female')}
              />
            </Form.Field>
            <Form.Field>
              <Radio label='Others' name='gender' value='Other'
                checked={gender === 'Other'}
                onClick={() => setGender('Other')}
              />
            </Form.Field>
          </Form.Group>
        </Form.Field>

        <Form.Field>
          <label>Food Preference</label>
          <Form.Group inline>
            <Form.Field>
              <Radio label='Vegetarian' name='food' value='Vegetarian'
                checked={foodPreference === 'Vegetarian'}
                onClick={() => setFoodPreference('Vegetarian')} />
            </Form.Field>
            <Form.Field>
              <Radio label='Non-Vegetarian' name='food' value='Non-Vegetarian'
                checked={foodPreference === 'Non-Vegetarian'}
                onClick={() => setFoodPreference('Non-Vegetarian')} />
            </Form.Field>
          </Form.Group>
        </Form.Field>

        <Form.Field style={{ marginBottom: '15px' }}>
          <label>Postal Address</label>
          <TextArea
            name="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            style={{ width: '100%', borderRadius: '4px' }}
          />
        </Form.Field>

        <Form.Group widths='equal' style={{ marginBottom: '15px' }}>
          <Form.Field>
            <label>City</label>
            <Input
              type="text"
              name="city"
              value={city}
              onChange={e => setCity(e.target.value)}
              style={{ width: '100%', borderRadius: '4px' }}
            />
          </Form.Field>
          <Form.Field>
            <label>Pincode</label>
            <Input
              type="number"
              name="pincode"
              value={pincode}
              onChange={e => setPincode(e.target.value)}
              style={{ width: '100%', borderRadius: '4px' }}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group widths='equal'>
          <Form.Field>
            <label>State</label>
            <Input
              type="text"
              name="state"
              value={state}
              onChange={e => setState(e.target.value)}
              style={{ width: '100%', borderRadius: '4px' }}
            />
          </Form.Field>
          <Form.Field>
            <label>Country</label>
            <Input
              type="text"
              name="country"
              value={country}
              onChange={e => setCountry(e.target.value)}
              style={{ width: '100%', borderRadius: '4px' }}
            />
          </Form.Field>
        </Form.Group>
      </Form>

      <Button className="ui button" type="button" color='blue' labelPosition='right' icon
        onClick={saveData}
      >
        Save & Continue <Icon name='angle double right' />
      </Button>
    </div >
  )
}
