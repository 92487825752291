import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Grid, Icon, Image } from 'semantic-ui-react'
import { MyContext } from '../App'
import { onValue, ref } from 'firebase/database';
import { db } from '../firebase';
import QRCode from "react-qr-code";
const idcg = require('../images/idcard.jpg')

function IDCard({ photo, type, name, id, category, serial, food }) {
  const { accomp, user, data } = useContext(MyContext)

  const names = [
    '',
    'IPS South Zonal Member',
    'IPS South Zonal Non-Member',
    'IPS South Zonal Senior Member',
    'PG Student',
    'Indian Corporate',
    'Kurnool IMA Member',
  ]

  function printDiv() {
    var printContents = document.getElementById('myDiv').innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }
  const [value, setValue] = useState(null)
  const previewStyle = {
    // height: 240,
    // width: 320,
    width: '100%',  // Adjust these values as needed
    height: '100%',
  }
  return (
    <div>
      <Card style={{ backgroundImage: `url(${idcg})`, backgroundSize: 'cover' }}>
        <Card.Content>
          <br />
          <br />
          <h4 style={{ textAlign: 'center', color: 'black' }}>Indian Psychiatric Society South Zonal Mid Term CME for Practitioners - 2024</h4>

          <Grid columns={photo ? 2 : 1} stretched celled>
            {photo &&
              <Grid.Column width={9} style={{ padding: 0 }}>
                <img src={photo} width={`100%`} height={150} style={{ objectFit: 'contain' }} />
              </Grid.Column>}
            <Grid.Column verticalAlign='middle' width={photo ? 7 : 16} style={{ padding: 0 }}>
              <div style={{ height: "auto", margin: "0 auto", height: 100, display: 'flex', verticalAlign: 'middle', maxWidth: 100, padding: 5, width: "100%" }}>
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={id.substring(0, 10)}
                  viewBox={`0 0 256 256`}
                />
              </div>
              {serial && <div style={{ color: 'black', textAlign: 'center' }}>Regd No:<br />IPSSZBCME0{serial}</div>}
            </Grid.Column>
          </Grid>
          <Card.Header>{name}</Card.Header>
          <p style={{ color: 'black' }}>{names[category]}</p>
          <p style={{ color: 'black', fontStyle: 'italic' }}>({food})</p>
        </Card.Content>
        <Card.Content>
          <a>
            <Icon name='user' />
            {type.toUpperCase()}
          </a>
        </Card.Content>
      </Card>
    </div>
  )
}
IDCard.defaultProps = {
  name: 'Your Name',
  type: "Accompanying Person",
  // photo: 'https://ipssozop24.com/wp-content/uploads/2024/01/team.jpg',
  photo: '',
  id: "123456789",
}
export default IDCard