import { onValue, ref, set } from 'firebase/database';
import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, FormField, Icon, Input, Modal, Segment } from 'semantic-ui-react'
import { db } from '../firebase';
import { MyContext } from '../App';
import { Link } from 'react-router-dom';

export default function Bonafide() {
  const { user, data, loading, setLoading } = useContext(MyContext)
  const [photo, setPhoto] = useState(null);
  const [fakePath, setfakePath] = useState('')
  const [box, setBox] = useState(false)
  const photoRef = ref(db, '/users/' + user?.uid + '/bonafide')

  let web = 'https://ipssozop24.com/'
  // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  //   web = 'http://localhost/qhaysky.com/'
  // }
  let headersList = {
    "Accept": "*/*",
    "User-Agent": "Thunder Client (https://www.thunderclient.com)"
  }
  useEffect(() => {
    onValue(photoRef, (snapshot) => {
      const data = snapshot.val();
      console.log(data);
      setPhoto(data);
    });
  }, [user.uid]);

  async function deletePhoto() {
    setBox(false)
    setLoading(true)
    let bodyContent = JSON.stringify({
      "id": photo.id,
      "uid": user.uid
    });

    let response = await fetch(web + "/wp-json/docs-conf/v1/delete-bonafide/", {
      method: "POST",
      body: bodyContent,
      headers: headersList
    });

    let data = await response.json();
    console.log(data);
    set(photoRef, null)
      .then(() => {
        console.log('Bonafide URL deleted from DB');
      })
      .catch((error) => console.log(error))
      .finally(() => { setLoading(false) })
  }

  async function saveData(e) {
    e.preventDefault();
    let bodyContent = new FormData();
    bodyContent.append("file", e.target.fileInput.files[0]);
    setLoading(true)
    let response = await fetch(web + "/wp-json/docs-conf/v1/upload-bonafide/", {
      method: "POST",
      body: bodyContent,
      headers: headersList
    });

    let data = await response.json();
    setLoading(false)
    setfakePath('')
    console.log(data);
    setPhoto(data.url[0])

    set(photoRef, data)
      .then(() => {
        console.log('Bonafide URL saved in DB');
      })
      .catch((error) => console.log(error))
  }
  return (

    <div>
      <Modal
        open={box}
        onOpen={() => setBox(true)}
        onClose={() => setBox(false)}
        closeIcon
        size='mini'
      >
        <Modal.Header>Delete this Bonafide</Modal.Header>
        <Modal.Actions>
          <Button color='grey' onClick={() => setBox(false)}>Cancel</Button>
          <Button color='red' onClick={deletePhoto}>Delete</Button>
        </Modal.Actions>
      </Modal>

      <h2>Bonafide Certificate</h2>
      <p><i>(Letter from HOD is mandatory for Post Graduate students of Psychiatry to register.)</i></p>

      {photo ?
        <>
          <img src={photo.url[0]} alt="" width={300} />
          <br />
          {!data?.payment?.delegate && <Button onClick={() => setBox(true)} color='red'>Delete this Bonafide</Button>}
          <hr />
          <Button className="ui button" type="button" color='blue' labelPosition='right' icon
            as={Link} to='/photo'
          >
            Next <Icon name='angle double right' />
          </Button>
        </>
        :
        <form onSubmit={saveData} encType='multipart/formdata'>
          <FormField>
            <label htmlFor="photo">Bonafide</label>
            <Input type="file" name="fileInput" id="photo" onChange={e => setfakePath(e.target.value)} />
          </FormField>
          <br />
          {fakePath ? <Button color='green' disabled={loading} loading={loading}>Upload</Button> : ''}
        </form>
      }

    </div>
  )
}
