import React, { useContext } from 'react'
import IDCard from './IDCard'
import { MyContext } from '../App'
import { Button } from 'semantic-ui-react'

function AllCards() {
  const { user, data } = useContext(MyContext)

  return (
    <>
      <div style={{ paddingBottom: 10 }}>
        <h3>Your registration completed successfully.</h3>
      </div>
      <h3>ID Cards for CME</h3>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 50, flexWrap: 'wrap', alignContent: 'flex-start' }}>

        {data ?
          <>
            <IDCard
              name={data.details?.salutation + ' ' + data.details?.fullName}
              category={data.details.category}
              id={user.uid}
              type={`Delegate`}
              photo={data?.photo?.url[0]}
              serial={data.details?.serial}
              food={data.details?.foodPreference}
            />
            {data?.persons ?
              Object.entries(data?.persons).map((item) => {
                if (!data.payment?.accom?.hasOwnProperty(item[0])) return
                if (data.payment.accom[item[0]]) {
                  return <IDCard name={item[1].name} id={item[0]} serial={item[1]?.serial} food={item[1].foodPreference} />
                }
              })
              : ''
            }
          </>
          : ''}
      </div>
    </>
  )
}

export default AllCards
