import React, { useEffect, useState } from 'react'
import { Button, Icon } from 'semantic-ui-react';

export default function A2HS({ user, data }) {
  let deferredPrompt;

  useEffect(() => {

    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      deferredPrompt = event;
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, [user, data]);

  const [isStandalone, setIsStandalone] = useState(false);
  const [installing, setInstalling] = useState(false)

  const handleInstallButtonClick = () => {

    if (deferredPrompt) {
      deferredPrompt.prompt();

      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the installation');
          setIsStandalone(true)
          setInstalling(true)
        } else {
          console.log('User dismissed the installation');
        }

        // deferredPrompt = null; // Reset the deferredPrompt variable
      });
    } else {
      window.alert('App aleady added to HomeScreen or this feature is not available on this device.')
    }
  };

  useEffect(() => {
    const checkStandaloneMode = () => {
      if (navigator.standalone !== undefined) {
        // iOS devices
        setIsStandalone(navigator.standalone);
      } else if (window.matchMedia('(display-mode: standalone)').matches) {
        // Android devices
        setIsStandalone(true);
      }
    };

    checkStandaloneMode();

    window.addEventListener('appinstalled', checkStandaloneMode);

    return () => {
      window.removeEventListener('appinstalled', checkStandaloneMode);
    };
  }, []);
  return (
    <>
      {!isStandalone && <Button color='blue' icon labelPosition='left' onClick={handleInstallButtonClick}>
        <Icon name='download' />Add to HomeScreen
      </Button>}
      <br />
      {installing && <p>Go to Home Screen and open the App</p>}
    </>
  )
}
