import React, { useContext, useEffect, useState } from 'react'
import { Button, Icon, Modal, Segment } from 'semantic-ui-react'
import { MyContext } from '../App';
import { onValue, push, ref, set } from 'firebase/database';
import { db } from '../firebase';
import PaymentReceipt from './Receipt';
import { Link } from 'react-router-dom';

export default function Payment({ amount, setAmount }) {
  const { user, data, ppersons, options, category, loading, setLoading, pids } = useContext(MyContext)
  const [links, setLinks] = useState([])
  const [oldlink, setOldlink] = useState(null)
  const [box, setBox] = useState(false)
  const [receipt, setReceipt] = useState(null)
  const [showDel, setShowDel] = useState(false)
  let [delAmount, setDelAmount] = useState(0)


  let headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json"
  }

  useEffect(() => {
    let params = new URLSearchParams(document.location.search);
    let callback = params.get("callback");
    if (callback === 'yes' && user && options) {
      startPayment(false)
    }
    if (data) {
      // console.log(typeof Object.values(data?.payment?.delegate)[0]);

      if (!data?.payment?.delegate && category != 3) {
        setDelAmount(options?.price.category[category])
        setShowDel(true)
      }
      if (data.payment?.history) {
        setLinks(data.payment.history)
        //   const createdLinks = Object.fromEntries(
        //     Object.entries(data.payment?.history).filter(([key, value]) => {
        //       return value?.data?.status === "created" && value?.data?.amount === amount
        //     })
        //   );
        //   const values = (Object.values(createdLinks))
        //   const link = values[values.length - 1]?.data.short_url
        //   setOldlink(link);
      }
    }
  }, [user, data, options])

  async function startEazyPayment() {
    setLoading(true)
    let params = {
      amount: amount,
      uid: user.uid,
    }
    if (delAmount) {
      params['delegate'] = 1
    }
    if (ppersons) {
      params['persons'] = pids;
    }
    const str = new URLSearchParams(params).toString()
    const short_url = `https://ipssozop24.com/icici?${str}`
    console.log(params);
    window.location.href = short_url;
  }


  async function startPayment(newLink = true) {
    setLoading(true)
    let bodyContent = {
      "uid": user.uid,
    }
    if (newLink) {
      bodyContent.callback_url = window.location.href
    } else {
      let params = new URLSearchParams(document.location.search);
      const razorpay_payment_link_id = params.get("razorpay_payment_link_id")
      if (razorpay_payment_link_id) {
        bodyContent.razorpay_payment_link_id = razorpay_payment_link_id;
      }
    }
    let mainurl = ''
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      mainurl = options.dev_url
    } else {
      mainurl = options.live_url
    }
    let response = await fetch(mainurl + "wp-json/docs-conf/v1/payment_links/", {
      method: "POST",
      body: JSON.stringify(bodyContent),
      headers: headersList
    });

    let data = await response.json();
    if (data.error) {
      console.log(data.error);
      return
    }
    if (!newLink) {
      const newParams = new URLSearchParams();
      newParams.set('page', 'Payment');
      const newURL = `${window.location.origin}${window.location.pathname}?${newParams.toString()}`;
      window.history.replaceState({}, document.title, newURL);
      setAmount(0)
    }
    if (data.short_url && newLink) {
      window.location.href = data.short_url
    } else {
      setLoading(false)
    }
    console.log(data)
  }

  function checkLink(id) {
    let urlparams = new URLSearchParams(window.location.search);
    urlparams.set('page', 'Payments');
    urlparams.set('razorpay_payment_link_id', id);
    urlparams.set('callback', 'yes');
    const updatedUrl = `${window.location.origin}${window.location.pathname}?${urlparams.toString()}`;
    window.history.replaceState({}, '', updatedUrl);
    startPayment(false)
  }
  function showReceipt(item) {
    setReceipt(item)
    setBox(true)
  }

  function activateSenior() {
    setLoading(true)
    fetch('https://ipssozop24.com/icici/senior.php?uid=' + user.uid)
      .then((res) => res.text())
      .then(text => {
        if (text === 'true') {
          console.log('Account activated');
        } else {
          alert('Error occured. Please contact Technical Support.')
        }
      })
      .catch(error => console.log(error))
      .finally(() => setLoading(false))
  }

  if (!data?.payment?.delegate || true) {
    return (<>
      <p style={{ color: 'brown', fontSize: 18, fontWeight: 'bold', textAlign: 'center', margin: 10 }}>Online payments closed. You can register on 1st June 2024 - 8:30AM at the venue itself. <br />Thank you.</p>
    </>)
  }

  if (category === '4' && !data?.bonafide?.id) {
    return (<>
      <h3>For PG Students Bonafide certificate is compulsory</h3>
      <h4>A letter signed by H.O.D should be uploaded, which proves that you are the student of particular institution.</h4>
      <Button color='blue' as={Link} to='/Bonafide'>Click here to upload</Button>
      <br />
      <br />
      <br />
      <br />
    </>
    )
  }

  if (!data?.details?.phone || !data?.details?.fullName || !data?.details?.mcinumber) {
    return (<>
      <h4>Please enter all details in Profile Page</h4>
      <Button color='blue' as={Link} to='/EditProfile'>Edit Profile Details</Button>
      <br />
      <br />
      <br />
      <br />
    </>
    )
  }

  return (
    <div>
      {amount > 0 ?
        <>
          <h3>Amount Payable: Rs.{amount}</h3>
          <div style={{ textAlign: 'center' }}>
            <h2>Payment</h2>
            <hr />
            <div style={tableStyle}>
              <table style={{ width: '100%' }}>
                <tbody>
                  {showDel ?
                    <>
                      <tr style={theROW}>
                        <th style={thCellStyle} colSpan={3}>Delegate</th>
                      </tr>
                      <tr style={theROW}>
                        <th style={thCellStyle}>No. of Persons</th>
                        <th style={thCellStyle}>Amount (Rs.)</th>
                        <th style={thCellStyle}>Total (Rs.)</th>
                      </tr>
                      <tr>
                        <td style={tdCellStyle}>1</td>
                        <td style={thCellStyleRight}>{Num(((options?.price.category[category]) / 1.18))}</td>
                        <td style={thCellStyleRight}>{Num(((options?.price.category[category]) / 1.18))}</td>
                      </tr>
                    </>
                    : ''
                  }
                  {ppersons ?
                    <>
                      <tr style={theROW}>
                        <th style={thCellStyle} colSpan={3}>Accompanying Persons</th>
                      </tr>
                      <tr style={theROW}>
                        <th style={thCellStyle}>No. of Persons</th>
                        <th style={thCellStyle}>Amount (Rs.)</th>
                        <th style={thCellStyle}>Total (Rs.)</th>
                      </tr>
                      <tr>
                        <td style={tdCellStyle}>{ppersons}</td>
                        <td style={thCellStyleRight}>{Num(((options?.price.person) / 1.18))}</td>
                        <td style={thCellStyleRight}>{Num((options?.price.person * ppersons / 1.18))}</td>
                      </tr>
                    </>
                    : ''
                  }
                  <tr style={theROW}>
                    {/* <th style={thCellStyle}></th> */}
                    <th style={thCellStyle}></th>
                    <th style={thCellStyle}>Subtotal</th>
                    <th style={thCellStyleRight}>{Num((amount / 1.18))}</th>
                  </tr>
                  <tr style={theROW}>
                    {/* <th style={thCellStyle}></th> */}
                    <th style={thCellStyle}></th>
                    <th style={thCellStyle}>SGST(9%)</th>
                    <th style={thCellStyleRight}>{Num((amount * 0.07627))}</th>
                  </tr>
                  <tr style={theROW}>
                    {/* <th style={thCellStyle}></th> */}
                    <th style={thCellStyle}></th>
                    <th style={thCellStyle}>CGST (9%)</th>
                    <th style={thCellStyleRight}>{Num((amount * 0.07627))}</th>
                  </tr>
                  <tr style={theROW}>
                    {/* <th style={thCellStyle}></th> */}
                    <th style={thCellStyle}></th>
                    <th style={totalCellStyle}>TOTAL</th>
                    <th style={{ ...totalCellStyle, textAlign: 'right' }}>{amount}.00</th>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <Button
              loading={loading}
              disabled={loading}
              size='large'
              // onClick={oldlink ? () => window.location.href = oldlink : startPayment}
              onClick={startEazyPayment}
              type="button" color='green' labelPosition='right' icon>
              Proceed to Payment
              <Icon name='angle double right' />
            </Button>
          </div>
        </>
        : ''
      }

      {(data?.details.category === '3' && !data?.payment?.delegate) &&
        <>
          <hr />
          <Button color='teal' onClick={activateSenior}>Activate My Senior Member Profile</Button>
        </>
      }
      {(data?.details.category === '3' && data?.payment?.delegate) &&
        <>
          <h2>Your Account is activated.</h2>
          <Button as={Link} to='/home' content='Go to Home Page' icon='arrow left' color='blue' labelPosition='left' />
        </>
      }

      {
        loading &&
        <center>
          <Icon name='spinner' loading={loading} size='big' />
        </center>
      }

      {
        Object.entries(links).length > 0 ?
          <>
            <Modal
              size='small'
              open={box}
              onOpen={() => setBox(true)}
              onClose={() => setBox(false)}
              closeIcon
            >
              <Modal.Content>
                <PaymentReceipt receipt={receipt} />
              </Modal.Content>
            </Modal>
            <hr />
            <h3>Payment History</h3>
            <table className="ui blue celled table">
              <tbody>
                {Object.entries(links).map((item) => <>
                  {item[1].data.status !== 'created' ?
                    <tr>
                      <td>{item[0]}
                        {/* <br />
                      {datetime(item[1].data.created_at)}
                      <br /> */}
                      </td>
                      <td>Rs.{item[1].data.amount}
                      </td>
                      <td>
                        {item[1].data.status.toUpperCase()}
                      </td>
                      <td>
                        <Button color='blue' onClick={() => showReceipt(item)}>Receipt</Button>
                      </td>

                      {/* {item[1].data.status === 'created' ?
                        <><br />
                        <Button
                        disabled={loading}
                        onClick={() => checkLink(item[0])}>Retry</Button>
                        </>
                      : ""} */}
                      {/* {(item[1].used != true && item[1].data.status === 'created') ?
                      <><br />
                      <Button
                      disabled={loading}
                      onClick={() => checkLink(item[0])}>Retry</Button>
                      </>
                    : ""} */}
                    </tr>
                    : ''}
                </>)}
              </tbody>
            </table>
            <Button as={Link} to='/home' content='Go to Home Page' icon='arrow left' color='blue' labelPosition='left' />
          </>
          : ""
      }
    </div>
  )
}

export function datetime(x) {
  const y = new Date(x * 1000)
  return y.toLocaleString()
}

function Num(n) {
  return Math.round(n * 100) / 100;
  // return n.toFixed(2);

}

const tableStyle = {
  width: '100%',
  maxWidth: '600px',
  margin: 'auto',
  borderCollapse: 'collapse',
  border: '1px solid #ddd',
};

const thCellStyle = {
  border: '1px solid #ddd',
  padding: '8px',
  textAlign: 'left',
};

const thCellStyleRight = {
  ...thCellStyle,
  textAlign: 'right'
}

const tdCellStyle = {
  border: '1px solid #ddd',
  padding: '8px',
  textAlign: 'left',
};

const theROW = {
  background: '#f8fafc',
};

const totalCellStyle = {
  ...tdCellStyle,
  fontSize: '18px',
  color: 'rgb(0, 4, 119)',
};