import React, { useContext } from 'react'
import AllCards from './AllCards'
import Profile from './Profile'
import { MyContext } from '../App'

export default function Home() {
  const { data } = useContext(MyContext)
  return (
    <>
      {data?.payment?.delegate === true ?
        <AllCards />
        :
        <Profile paid={false} />}
    </>
  )
}
